import { RemoteConfig, RemoteConfigFeature, RemoteConfigProperty } from './types'

export const DEFAULT_REMOTE_CONFIG_FEATURE: RemoteConfigFeature = {
	[RemoteConfigProperty.USERS]: [],
	[RemoteConfigProperty.CUSTOMERS]: [],
	[RemoteConfigProperty.AVAILABLE_TO_ALL]: false,
}

// default values for each remote config
// NOTE: we populate these values on firebase as JSON
export const REMOTE_CONFIG_DEFAULTS: Record<RemoteConfig, string> = {
	[RemoteConfig.LABELS]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.PRICEBOOKS]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.CLICK_AND_COLLECT]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.PRODUCT_COMMENTS]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.MANAGE_SCHEDULED_INVOICES]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
	[RemoteConfig.ACCOUNT_PAYMENT]: JSON.stringify(DEFAULT_REMOTE_CONFIG_FEATURE),
}
