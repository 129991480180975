import { BranchProductDetail } from 'typescript-fetch-api'

export enum SpecSheet {
	SPECS = 'specs',
	BROCHURES = 'brochures',
	MSDS = 'msds',
	WARRANTY = 'warranty',
	SDOC = 'sdoc',
	INSTALL = 'install',
}

export interface BranchesAvailability {
	northern: BranchProductDetail[],
	central: BranchProductDetail[],
	lowerCentral: BranchProductDetail[],
	southern: BranchProductDetail[],
}

export enum ProductWelsSuffix {
	'WELS Water Efficiency' = 'Star Rated',
	'Water Consumption' = 'Litres Per Minute',
}