import { Button as MuiButton, ButtonProps, styled } from '@mui/material'

import colors from '../../utils/colorProvider'

const FIXED_WIDTH = 177

const styles = {
	red: {
		backgroundColor: colors.brinkPink,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.blush,
			'@media (hover: none)': {
				backgroundColor: colors.blush,
			}
		}
	},
	warning: {
		color: colors.alizarin,
		'&:hover': {
			backgroundColor: 'transparent',
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			}
		}
	},
	white: {
		backgroundColor: 'transparent',
		border: `1px solid ${colors.white}`,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.white,
			color: colors.silver,
			'@media (hover: none)': {
				backgroundColor: colors.white,
				color: colors.silver,
			},
		},
	},
	whiteContained: {
		backgroundColor: colors.white,
		color: colors.black,
		'&:hover': {
			backgroundColor: colors.snowDrift,
			color: colors.black,
			'@media (hover: none)': {
				backgroundColor: colors.snowDrift,
				color: colors.black,
			},
		},
	},
	hnz: {
		backgroundColor: colors.watercourse,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.eucalyptus,
			'@media (hover: none)': {
				backgroundColor: colors.eucalyptus,
			},
		},
	},
	black: {
		backgroundColor: colors.black,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.black,
			'@media (hover: none)': {
				backgroundColor: colors.black,
			},
		},
	},
	keppel: {
		backgroundColor: colors.keppel,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.mediumAquamarine,
			'@media (hover: none)': {
				backgroundColor: colors.mediumAquamarine,
			},
		},
	},
	roundContained: {
		backgroundColor: colors.nightRider,
		color: colors.white,
		borderRadius: 40,
		minHeight: 70,
		letterSpacing: 0.5,
		'&:hover': {
			backgroundColor: colors.black,
			'@media (hover: none)': {
				backgroundColor: colors.black,
			},
		},
		'&.Mui-disabled': {
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			color: 'rgba(0, 0, 0, 0.38)',
		},
	},
	roundOutlined: {
		backgroundColor: 'transparent',
		color: colors.black,
		border: `4px solid ${colors.black}`,
		borderRadius: 40,
		minHeight: 70,
		letterSpacing: 0.5,
		'&:hover': {
			backgroundColor: colors.black,
			color: colors.white,
			'@media (hover: none)': {
				backgroundColor: colors.black,
				color: colors.white,
			},
		},
		'&.Mui-disabled': {
			borderColor: colors.nobel,
			color: colors.grey,
		},
	},
}

interface Props extends ButtonProps {
	isFixedWidth?: boolean
	kind: 'red' | 'warning' | 'white' | 'whiteContained' | 'hnz' | 'black' | 'keppel' | 'roundContained' | 'roundOutlined'
}

const Button = styled(MuiButton)<Props>(({ isFixedWidth, kind }) => ({
	width: isFixedWidth ? FIXED_WIDTH : undefined,
	...styles[kind],
}))

export default Button
