import * as React from 'react'
import { Divider } from '@mui/material'

import * as Container from '../containers/CustomerOrdersMenu'
import MenuPopper from '../../platform/components/MenuPopper'
import { getEnvironment } from '../../platform/functions'
import { Environment } from '../../../common/api/types'

const environment = getEnvironment()
const showInterimOrders = environment != Environment.PRODUCTION && environment != Environment.TEST

class CustomerOrdersMenuPopper extends React.PureComponent<Container.OwnProps & Container.Props & Container.Actions> {

	navigateToOrdersTab = () => {
		this.props.navigateToOrdersTab()
		this.props.onClickAway()
	}

	navigateToInterimOrders = () => {
		this.props.navigateToInterimOrdersTab()
		this.props.onClickAway()
	}

	render() {
		const { anchorElement, onClickAway, isHnzAccount, onCloseSubmenu } = this.props

		return (
			<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Orders" onCloseSubmenu={onCloseSubmenu}>
				<div className="menu-drawer__item" onClick={this.navigateToOrdersTab}>
					<span />
					<span className="menu-drawer__item--title">Current Orders</span>
					<span />
				</div>

				{showInterimOrders &&
					<>
						<Divider className="menu-drawer__footer--divider" />

						<div className="menu-drawer__item" onClick={this.navigateToInterimOrders}>
							<span />
							<span className="menu-drawer__item--title">Interim Orders</span>
							<span />
						</div>
					</>
				}
			</MenuPopper>
		)
	}
}

export default CustomerOrdersMenuPopper