import { RootStoreState } from '../root'
import { RemoteConfig, RemoteConfigFeature, RemoteConfigProperty } from './types'

/**
 * Checks if a specific remote config is enabled.
 * We use this to show/hide features and content.
 * @param remoteConfig the remote config key
 * @param store the store
 * @returns true if the config is enabled, and false if otherwise
 */
export const isEnabledRemoteConfig = (remoteConfig: RemoteConfig, store: RootStoreState): boolean => {
    const savedRemoteCofig: RemoteConfigFeature = store.remoteConfig[remoteConfig]

    // check first if the feature has been made available to all so we don't have to do extra checks for the users/customers
    const isAvailableToAll = savedRemoteCofig[RemoteConfigProperty.AVAILABLE_TO_ALL]
    if (isAvailableToAll) return true

    // grab the IDs to check against the config
    const userId = store.auth.userId && store.auth.userId.toString()
    const customerId = store.order.selectedAccount && store.order.selectedAccount.customerId?.toString()

    let isEnabledForUser = false
    if (userId) {
        isEnabledForUser = savedRemoteCofig[RemoteConfigProperty.USERS].some(item => item.toString() === userId)
    }

    let isEnabledForAccount = false
    if (customerId) {
        isEnabledForAccount = savedRemoteCofig[RemoteConfigProperty.CUSTOMERS].some(item => item.toString() === customerId)
    }

    return isEnabledForUser || isEnabledForAccount
}